import ArrayProxy from '@ember/array/proxy';
import { computed } from '@ember/object';
import { not, readOnly } from '@ember/object/computed';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import { resolve } from 'rsvp';

import Model from './model';

const ArrayPromiseProxy = ArrayProxy.extend(PromiseProxyMixin);
const { attr, hasMany, belongsTo, PromiseObject } = DS;

export default Model.extend({
  session: service(),

  title: attr('string'),

  type: attr('string'),

  free: attr('boolean'),

  disabledUntilPublished: attr('boolean'),

  published: attr('boolean'),

  for_trial: attr('boolean'),

  for_single_day_trial: attr('boolean', { defaultValue: false }),

  metadata: attr(),

  fields: attr(),

  subjects: attr(),

  stages: attr(),

  released: attr('only-date'),

  externalUrl: attr('string'),

  teachersOnly: attr('boolean'),

  license_time: attr('number'),

  unlimited_license_time: attr('boolean'),

  hasProductPage: attr('boolean'),

  hasCoursePage: attr('boolean'),

  // PROPERTIES

  forTrial: computed('for_trial', 'for_single_day_trial', function () {
    return this.get('for_trial') || this.get('for_single_day_trial');
  }),

  // RELATIONSHIPS

  books: hasMany('entity', { inverse: null }),

  image: belongsTo('material'),

  cover: belongsTo('material'),

  // COMPUTED

  userBooks: computed('books', 'session.user.isTeacher', function () {
    const promise = resolve(this.books).then((books) => {
      return books.filter((book) => {
        const isTeacherOnlyBook = book?.body?.teacher_only || false;
        return this.session.user.isTeacher || !isTeacherOnlyBook;
      });
    });

    return ArrayPromiseProxy.create({ promise });
  }),

  internalProduct: computed('userBooks.@each.type', function () {
    return this.userBooks?.filterBy('type', 'books')?.length > 0;
  }),

  isExternal: computed('hasCoursePage', function () {
    return this.get('hasCoursePage') === false;
  }),

  isInternal: not('isExternal'),

  isExtraMaterial: computed('free', 'unlimited_license_time', function () {
    return this.get('free') && this.get('unlimited_license_time');
  }),

  authors: computed('metadata.authors', function () {
    const authors = this.get('metadata.authors');

    if (!authors) return [];

    return authors.map((author) => {
      const parts = author.split(',');

      if (parts.length > 1) {
        const lastName = parts.shift().trim();
        const firstName = parts.join(',').trim();

        return firstName + ' ' + lastName;
      } else {
        return author;
      }
    });
  })
});
